$(document).ready(function () {


    /*// Ajax submit blueprint

    $('.ajax-submitable').submit(function (event) {
        event.preventDefault();

        var form = $(this);
        console.log(form);
        var formData = form.serialize();

        form.find('.form-group').removeClass('has-error'); // remove error classes from inputs
        form.find('.help-block').remove(); // remove error texts from inputs
        form.find('.alert').remove(); // remove all alerts

        //hide submit button
        form.find('.submit').hide();
        form.find('.loading').show();

        // process the form
        $.ajax({
                   type: 'POST',
                   url: form.attr('action'),
                   data: formData,
                   dataType: 'json',
                   encode: true
               }).done(function (data) {
            // here we will handle errors and validation messages
            if (!data.success) {
                // print errors
                $.each(data.errors, function (id, errors) {

                    var element = form.find('#' + id);

                    if (element.is(":visible")) {
                        // add error class to parent div
                        element.parent().addClass('has-error');

                        // print all errors for this element
                        $.each(errors, function (counter, err) {
                            $('#' + id).parent().append('<div class="help-block">' + err + '</div>');
                        });
                    }
                    else {
                        // prepend alerts with messages so they would be visible
                        $.each(errors, function (counter, err) {
                            form.prepend('<div class="alert alert-danger">' + err + '</div>');
                        });
                    }

                });
            }
            else {
                // display success message!
                form.prepend('<div class="alert alert-success">' + data.message + '</div>');
                // clear inputs
                $('.form-control').each(function () {
                    $(this).val('')
                });

                // prepend html from response if data prepend is specified
                if (form.attr('data-prepend')) {
                    $('.no-entry-found').remove();
                    $('#' + form.data('prepend')).prepend(data.html);
                }

                // append html from response if data append is specified
                if (form.attr('data-append')) {
                    $('.no-entry-found').remove();
                    $('#' + form.data('append')).append(data.html);
                }
            }

            //show submit button
            form.find('.submit').show();
            form.find('.loading').hide();
        });
    });*/
    $(document).on('submit', '.ajax-submitable', function (event) {
        event.preventDefault();

        var form = $(this);
        console.log(form);

        var formData;

        if (form.attr('data-data-prepare-function')) {
            var dataPrepareFunction = form.data('data-prepare-function');
            formData = eval(dataPrepareFunction + "()");
        }
        else {
            form.find('input[type=text]').each(function() {
                $(this).val($(this).val().trim());
            });
            formData = form.serialize();
        }

        console.log(formData);

        form.find('.form-group').removeClass('has-error'); // remove error classes from inputs
        form.find('.help-block').remove(); // remove error texts from inputs
        form.find('.alert').remove(); // remove all alerts

        //hide submit button
        form.find('.submit').hide();
        form.find('.loading').show();

        // process the form
        $.ajax({
                   type: 'POST',
                   url: form.attr('action'),
                   data: formData,
                   dataType: 'json',
                   encode: true
               }).done(function (data) {
            // here we will handle errors and validation messages
            if (!data.success) {

                // print errors
                $.each(data.errors, function (id, errors) {
                    id = id.replace(/\./g, "\\.")
                    var element = form.find('#' + id);

                    if (element.is(":visible")) {
                        // add error class to parent div
                        var parent = element.closest(".form-group");
                        parent.addClass('has-error');

                        // print all errors for this element
                        $.each(errors, function (counter, err) {
                            var res = parent.append('<div class="help-block">' + err + '</div>');
                        });
                    }
                    else {
                        // prepend alerts with messages so they would be visible
                        var errorBox = $('#ajax-error-messages-box');
                        $.each(errors, function (counter, err) {
                            if (errorBox.length > 0) errorBox.append('<div class="alert alert-danger">' + err + '</div>');
                            else form.prepend('<div class="alert alert-danger">' + err + '</div>');
                        });
                    }

                });

                //show submit button
                form.find('.submit').show();
                form.find('.loading').hide();

                return false;// To prevent selecting the other tab

            }
            else {
                // Redirect if returned data contains redirect attribute
                if (typeof(data.redirect) !== 'undefined') {
                    window.location.assign(data.redirect);
                    return true;
                }
                // Reload current page if reload attribute is set
                else if(typeof(data.reload) !== 'undefined' ){
                    window.location.reload();
                }
                else {
                    // display success message!
                    if (data.message) {
                        form.prepend('<div class="alert alert-success">' + data.message + '</div>');
                    }
                    // clear inputs
                    if (! form.data('preserve-input-values') || form.data('preserve-input-values') == false) {
                        $('.form-control').each(function () {
                            $(this).val('')
                        });
                    }

                    // prepend html from response if data prepend is specified
                    if (form.attr('data-prepend')) {
                        $('.no-entry-found').remove();
                        $('#' + form.data('prepend')).prepend(data.html);
                    }

                    // append html from response if data append is specified
                    if (form.attr('data-append')) {
                        $('.no-entry-found').remove();
                        $('#' + form.data('append')).append(data.html);
                    }

                    if (form.attr('data-after-success')) {
                        var successCallback = form.data('after-success');
                        eval(successCallback + "()");
                    }
                }

            }

            //show submit button
            form.find('.submit').show();
            form.find('.loading').hide();
        });
    });

    // Ajax page load

    /* $(document).on('click', '.ajax-page-load', function (event) {

     event.preventDefault();

     var button = $(this);
     var token = button.data('token');
     var sendData = {_token: token};

     $("#content-wrap").load(button.attr('href') + "#content-wrap", sendData);
     */
    /*// send request
     $.ajax({
     type: 'POST',
     url: button.attr('href'),
     data: sendData,
     dataType: 'json',
     encode: true
     }).done(function (data) {
     // here we will handle errors and validation messages
     if (!data.success) {
     event.default();
     }
     else {
     // show new content
     $('#content-wrap').html(data.html);
     //change current uri
     }
     });*/
    //});


    // >> Article comments submit

    $('#formAddArticleComment').submit(function (event) {
        event.preventDefault();

        var form = $('#formAddArticleComment');
        var formData = form.serialize();

        $('.form-group').removeClass('has-error'); // remove the error class
        $('.help-block').remove(); // remove the error text
        $('.alert-danger').remove(); // remove error alerts

        //hide submit button
        form.find('.submit').hide();
        form.find('.loading').show();

        // process the form
        $.ajax({
                   type: 'POST', // define the type of HTTP verb we want to use (POST for our form)
                   url: form.data('url'),//'/store_article_comment', // the url where we want to POST
                   data: formData, // our data object
                   dataType: 'json', // what type of data do we expect back from the server
                   encode: true
               })
            // using the done promise callback
            .done(function (data) {

                      // here we will handle errors and validation messages
                      if (!data.success) {

                          // print errors
                          $.each(data.errors, function (id, errors) {

                              var element = form.find('#' + id);


                              if (element.is(":visible")) {
                                  // add error class to parent div
                                  element.parent().addClass('has-error');

                                  // print all errors for this element
                                  $.each(errors, function (counter, err) {
                                      $('#' + id).parent().append('<div class="help-block">' + err + '</div>');
                                  });
                              }
                              else {
                                  // prepend alerts with messages so they would be vidible
                                  $.each(errors, function (counter, err) {
                                      $('form').prepend('<div class="alert alert-danger">' + err + '</div>');
                                  });
                              }

                          });
                      }
                      else {
                          // ALL GOOD!
                          form.prepend('<div class="alert alert-success">' + data.message + '</div>');
                          $('.form-control').each(function () {
                              $(this).val('')
                          }); // clear inputs
                          $('#comments').append(data.comment);
                          $('.alert-info').remove();
                      }

                      //show submit button
                      form.find('.submit').show();
                      form.find('.loading').hide();
                  });
    });


    // comment reply

    $('#comments').on('click', '.link-reply', function (event) {
        event.preventDefault();

        var form = $('#formAddArticleComment');
        var input = $('#input_parent_id');

        var comment = $(this).parent().parent().parent().find('.comment-entry').html();

        form.find('.alert-info').remove();

        form.prepend('<div class="alert alert-info alert-dismisible" role="alert">'
                     + $(this).data('reply') + ' </br> </br>' + comment + '</br> <button data-dismiss="alert" class="close-reply btn btn-primary">' + $(this).data('cancel') + '</button></div>');


        input.val($(this).data('id'));

        $('html, body').animate({
                                    scrollTop: $("#divAddComment").offset().top - 50
                                }, 800);

    });

    // cancel comment reply

    $('#formAddArticleComment').on('click', '.close-reply', function (event) {
        //event.preventDefault();
        $('#input_parent_id').val(0);
    });


    // >> GuestBook entry submit

    $('#formGuestBook').submit(function (event) {
        event.preventDefault();

        var form = $('#formGuestBook');
        var formData = form.serialize();

        $('.form-group').removeClass('has-error'); // remove the error class
        $('.help-block').remove(); // remove the error text
        $('.alert-danger').remove(); // remove error alerts

        //hide submit button
        $('.submit').hide();
        $('.loading').show();

        // process the form
        $.ajax({
                   type: 'POST', // define the type of HTTP verb we want to use (POST for our form)
                   url: form.data('url'),//'/store_article_comment', // the url where we want to POST
                   data: formData, // our data object
                   dataType: 'json', // what type of data do we expect back from the server
                   encode: true
               })
            // using the done promise callback
            .done(function (data) {

                      // log data to the console so we can see
                      console.log(data);

                      // here we will handle errors and validation messages
                      if (!data.success) {

                          // print errors
                          $.each(data.errors, function (id, errors) {

                              var element = form.find('#' + id);


                              if (element.is(":visible")) {
                                  // add error class to parent div
                                  element.parent().addClass('has-error');

                                  // print all errors for this element
                                  $.each(errors, function (counter, err) {
                                      $('#' + id).parent().append('<div class="help-block">' + err + '</div>');
                                  });
                              }
                              else {
                                  // prepend alerts with messages so they would be vidible
                                  $.each(errors, function (counter, err) {
                                      $('form').prepend('<div class="alert alert-danger">' + err + '</div>');
                                  });
                              }

                          });
                      }
                      else {
                          // ALL GOOD!
                          form.prepend('<div class="alert alert-success">' + data.message + '</div>');
                          $('.form-control').each(function () {
                              $(this).val('')
                          }); // clear inputs
                          $('#comments').prepend(data.entry);
                          $('.alert-info').remove();
                      }

                      //show submit button
                      $('.submit').show();
                      $('.loading').hide();
                  });
    });


    // >>> Portfolio show image

    // pressing key to move
    /*
     $(document).keyup(function(e)
     {
     console.log(e.keyCode);

     if(e.keyCode == 37)     // left arrow pressed
     {
     $('#btn-previous').trigger("click");
     }

     if(e.keyCode == 39)     // right arrow pressed
     {
     $('#btn-next').trigger("click");
     }

     if(e.keyCode == 38)     // up arrow pressed
     {
     $('#btn-index').trigger("click");
     }
     });
     */
    $('#btn-next, #btn-previous').click(function (event) {
        event.preventDefault();

        var image = $('#main-image');
        var button = $(this);
        var prev = $('#btn-previous');
        var next = $('#btn-next');

        // change url
        history.pushState({}, '', button.attr("href"));


        // get new data
        var sendData = {
            'category': button.data('category'),
            'id': button.data('id'),
            '_token': button.data('token')
        };

        $.ajax({
                   type: 'POST',
                   url: button.data('ajax-path'),
                   data: sendData,
                   dataType: 'json', // what type of data do we expect back from the server
                   encode: true
               })
            // using the done promise callback
            .done(function (data) {

                      // load new image
                      image.attr("src", data.img);

                      prev.removeClass('disabled');
                      next.removeClass('disabled');

                      // update breadcrumbs
                      $('#title').html(data.title);
                      $('#breadcrumb').html(data.title);

                      //update data attributes
                      prev.data('id', data.prev);
                      prev.data('token', data.token);
                      prev.attr('href', data.urlPrev);

                      next.data('id', data.next);
                      next.data('token', data.token);
                      next.attr('href', data.urlNext);


                      // disable button if there is no other image
                      if (data.prev == null) prev.addClass('disabled');
                      if (data.next == null) next.addClass('disabled');


                      // update photo stats
                      $('#author').html(data.author);
                      $('#species').html(data.species);
                      $('#camera').html(data.camera);
                      $('#lens').html(data.lens);

                      // upadate similar images


                      console.log(data);
                  });

    });


});